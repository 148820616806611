import { clearAllBodyScrollLocks } from "body-scroll-lock-upgrade"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"

import useThemeFeatures from "../../../hooks/useThemeFeatures.js"
import useThemeHeader from "../../../hooks/useThemeHeader.js"
import ConfirmationPopinContent from "../../../pages/home/ConfirmationPopinContent.jsx"
import { getLeagueAds, getUserLeagueTeams } from "../../../pages/league/api/myLeague.js"
import { resolveTeamPJT } from "../../../pages/league/service/service.js"
import { SELECTED_LEAGUE_TEAM_KEY, useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import { loadUrlQueryParameters } from "../../../store/actions/scaffold/index.js"
import { isBusinessEnvironment } from "../../../utilities/helpers.js"
import Preloader from "../../loaders/preloader/preloader.jsx"
import TabSwitcher from "../../TabSwitcher/TabSwitcher.jsx"
import { useTheme } from "../../ThemeContext/ThemeContext.jsx"
import { LeagueHeader } from "../LeagueHeader/LeagueHeader.jsx"
import { TeamSwitcher } from "../TeamSwitcher/TeamSwitcher.jsx"

import "./LeagueLayout.scss"

export const LeagueLayout = ({ title, withMainSwitcher, withHeading = true }) => {
    const [ isLoading, setIsLoading ] = useState(false)

    const userLoaded = useSelector(state => state.userLoaded)
    const dispatch = useDispatch()

    const theme = useTheme()
    const themeHeader = useThemeHeader()
    const themeFeatures = useThemeFeatures()
    const [ searchParams, setSearchParams ] = useSearchParams()

    const {
        selectedLeagueTeam,
        setSelectedLeagueTeam,
        setLeagueTeams,
        setSelectedTeamData,
        leagueTeams,
        setAds,
    } = useLeagueStore()

    const { teamId  } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [ hasFallBack, setHasFallBack ] = useState(true)

    useEffect(() => {
        if (selectedLeagueTeam?.id && location.pathname === LeaguePath.leagueInfosBase) {
            selectTeam(selectedLeagueTeam)
        } else  /* istanbul ignore next */if (!teamId && !selectedLeagueTeam?.id && leagueTeams.length) {
            selectTeam(leagueTeams[0])
        }
    }, [ location ])

    useEffect(() => {
        const fallBackRedirection = themeFeatures([ LeaguePath.listingLeague, LeaguePath.listingLeague, LeaguePath.toLeagueInfosHome("visitor") ])
        if ((leagueTeams.length === 0 || (!selectedLeagueTeam?.id && !!teamId)) && hasFallBack) {
            setIsLoading(true)
            getUserLeagueTeams().then((teams) => {
                if (teams.length) {
                    setLeagueTeams(teams)
                    if (teamId && isNaN(+teamId)) {
                        const foundTeam = teams.find((team) => team.id === parseInt(teamId))
                        if (foundTeam) {
                            selectTeam(foundTeam)
                        } else {
                            toLeagueInfoHome(teams[0])
                        }
                    } else {
                        const localStorageTeamId = localStorage.getItem(SELECTED_LEAGUE_TEAM_KEY)
                        const team = localStorageTeamId ? teams?.find(
                            team => team?.id === parseInt(localStorageTeamId),
                        ) ?? teams[0] : teams[0]
                        selectTeam(team)
                        toLeagueInfoHome(team)
                    }
                } else {
                    setHasFallBack(themeFeatures([ true, true, false ]))
                    navigate(fallBackRedirection, {
                        replace: true,
                    })
                }
            }).finally(() => setIsLoading(false))
        } else if (!hasFallBack && isBusinessEnvironment()) {
            navigate(fallBackRedirection, {
                replace: true,
            })
        }
    }, [ userLoaded.contactId, selectedLeagueTeam, location?.pathname, theme ])

    useEffect(() => {
        const action = searchParams?.get("action")
        const invitation = searchParams?.get("invitation")

        if (action === "tokenActivation" && userLoaded !== undefined && Object.keys(userLoaded).length && (
            [ "TYPE_FRIENDSHIP", "TYPE_FRIENDSHIP_AND_LEAGUE", "TYPE_FRIENDSHIP_AND_CUP" ].includes(invitation)
        )) {
            openConfirmationPopin()
        }
    }, [ userLoaded.contactId, location?.pathname ])

    const openConfirmationPopin = () => {
        ModalHandler.show(ConfirmationPopinContent, {
            locationDetails: null,
            type: "friendship",
            withLeagueInvitation: searchParams?.invitation === "TYPE_FRIENDSHIP_AND_LEAGUE",
        }).then(() => {
            setSearchParams({})
            dispatch(loadUrlQueryParameters(null))
            clearAllBodyScrollLocks()
        })
    }

    useEffect(() => {
        getLeagueAds(themeHeader - 1).then(setAds)
    }, [ theme ])

    const selectTeam = (team) => {
        if (team?.id) {
            setSelectedLeagueTeam({
                ...team,
                shareLink: resolveTeamPJT(selectedLeagueTeam, theme, userLoaded),
            })

            setSelectedTeamData({
                id: team.id,
                leagueId: team.leagueId,
            })
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (selectedLeagueTeam?.id
                && selectedLeagueTeam?.id !== parseInt(teamId)
        ) {
            selectTeam(selectedLeagueTeam)
            if (!teamId && withMainSwitcher) {
                toLeagueInfoHome(selectedLeagueTeam)
            }
        }
    }, [ selectedLeagueTeam?.id, location ])

    const toLeagueInfoHome = (team) => {
        dispatch(loadUrlQueryParameters(null))
        navigate(LeaguePath.toLeagueInfosHome(team.id) + location.search, {
            replace: true,
        })
    }

    const teamSwitcher = useMemo(() => {
        return <TeamSwitcher/>
    }, [ selectedLeagueTeam?.id ])

    const tabs = useMemo(() => {
        return [
            {
                name: "Général",
                route: LeaguePath.toLeagueInfosHome(selectedLeagueTeam?.id),
            },
            {
                name: "Classement",
                route: LeaguePath.toLeagueInfosRanking(selectedLeagueTeam?.id),
            },
            {
                name: "Calendrier",
                route: LeaguePath.toLeagueInfosCalendar(selectedLeagueTeam?.id),
            },
        ]
    }, [ selectedLeagueTeam?.id ])

    const isExtendedLayout = location.pathname.includes(LeaguePath.leagueInfosHome)
    || matchPath(LeaguePath.leagueTeamDetails, location.pathname)
    
    return (
        <>
            {withHeading ? (
                <>
                    <LeagueHeader
                        title={title}
                    >
                        {teamSwitcher}
                    </LeagueHeader>
                    {isLoading ? (
                        <Preloader fixed/>
                    ) : (
                        <div className={"c-row justify-center league-layout"}>
                            <div className={classNames("c-col c-col--10 c-col--sm--12 no-padding", {
                                "c-col--10": !isExtendedLayout,
                                "c-col--12": isExtendedLayout,
                            })}>
                                {withMainSwitcher && !!leagueTeams?.length && (
                                    <TabSwitcher tabs={tabs} />
                                )}
                                <Outlet/>
                            </div>
                        </div>
                    )}
                </>
            ) : isLoading ? (
                <Preloader fixed/>
            ) : (
                <Outlet/>
            )}
        </>
    )
}

LeagueLayout.propTypes = {
    title: PropTypes.string.isRequired,
    withHeading: PropTypes.bool,
    withMainSwitcher: PropTypes.bool,
}
