import { bool, string } from "prop-types"
import { useSelector } from "react-redux"

import FooterHamburger from "./footerHamburger/footerHamburger"
import useNotLogFooter from "../../../hooks/useNotLogFooter"
import useThemeFeatures from "../../../hooks/useThemeFeatures.js"
import { useIsWebview, useTheme } from "../../ThemeContext/ThemeContext"

import "./footer.scss"

function Footer({ customFooterClass, forceVisitorFooter = false }) {
    const theme = useTheme()
    const userOpen = useSelector(state => state.userSelect.open)
    const menuOpen = useSelector(state => state.menuSelect.open)
    const userLoaded = useSelector(state => state.userLoaded)
    const auth = userLoaded?.id
    const themeFeatures = useThemeFeatures()
    const isNotLogFooter = forceVisitorFooter ? forceVisitorFooter :  useNotLogFooter()
    const isWebview = useIsWebview()

    const themeData = {
        "theme-business": {
            domain: "https://businessleague.fr/",
            links: {
                contact: "https://www.businessleague.fr/inscrire-equipe/",
                mentionsLegales: "https://www.businessleague.fr/mentions-legales-business-league/",
                politiqueConfidentialite: "https://businessleague.fr/politique-de-confidentialite/",
            },
            logoSrc: "/assets/images/business/logo-business-white.svg",
        },
        "theme-padel": {
            domain: "https://www.urbanpadel.fr/",
            links: {
                contact: "https://www.urbanpadel.fr/contact/",
                mentionsLegales: "https://www.urbanpadel.fr/mentions-legales/",
                politiqueConfidentialite: "https://www.urbanpadel.fr/politique-de-confidentialite/",
            },
            logoSrc: "/assets/images/padel/logo/logo-padel-footer.svg",
        },
        "theme-soccer": {
            domain: "https://www.urbansoccer.fr/",
            links: {
                contact: "https://www.urbansoccer.fr/contact/",
                mentionsLegales: "https://www.urbansoccer.fr/mentions-legales/",
                politiqueConfidentialite: "https://www.urbansoccer.fr/politique-de-confidentialite/",
            },
            logoSrc: "/assets/images/Logo-US-white.svg",
        },
    }

    const { domain, logoSrc, links } = themeData[theme] || {}

    function getFooterOpenedClass() {
        if (userOpen) {return "overlayProfilOpened"}
        if (menuOpen) {return "overlayMenuOpened"}
        return ""
    }

    function renderMobileFooter() {
        return (
            <div className="authMobileFooter show-st">
                <div>
                    <a href={domain} target="_blank" rel="noreferrer">
                        <img className="urbansoccer-logo" src={logoSrc} />
                    </a>
                </div>
                <div className="footer-side-div">
                    <a
                        href={links.contact}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <span>Contact</span>
                    </a>
                    <div>
                        <a href={links.mentionsLegales} target="_blank" rel="noreferrer">
                            <span>Mentions légales</span>
                        </a>
                        <span className="separator">-</span>
                        <a href={links.politiqueConfidentialite} target="_blank" rel="noreferrer">
                            <span>Politique de données personnelles</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    function renderDesktopFooter() {
        return (
            <div className="hide-st desktopFooter">
                <div>
                    <a href={domain} target="_blank" rel="noreferrer">
                        <img className="urbansoccer-logo" src={logoSrc} />
                    </a>
                </div>
                <div className="footer-side-div">
                    <a href={links.mentionsLegales} target="_blank" rel="noreferrer">
                        <span>Mentions légales</span>
                    </a>
                    <a href={links.politiqueConfidentialite} target="_blank" rel="noreferrer">
                        <span>Politique de données personnelles</span>
                    </a>
                    <a href={themeFeatures([ links.contact, links.contact, links.contact ])} target="_blank" rel="noreferrer">
                        <span>Contact</span>
                    </a>
                    {!isWebview && (
                        <a
                            href=""
                            data-testid="cookie"
                            onClick={(e) => {
                                e.preventDefault()
                                window.openAxeptioCookies()
                            }}
                        >
                            Cookies
                        </a>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div
            id="footer"
            className={customFooterClass ? `${customFooterClass} ${getFooterOpenedClass()}` : getFooterOpenedClass()}
        >
            {isNotLogFooter ? renderMobileFooter() : (auth && (
                <div className="show-st footer-mobile-container">
                    <FooterHamburger footerType="reserver" />
                    <FooterHamburger footerType="switch" />
                    <FooterHamburger footerType="menu" />
                </div>
            ))}
            {renderDesktopFooter()}
        </div>
    )
}

Footer.propTypes = {
    customFooterClass: string,
    forceVisitorFooter: bool,
}

export default Footer
